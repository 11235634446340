import React, { useState, useEffect } from "react";

const NOTES = [
    "C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"
];

function transposeChord(chord, steps) {
    let rootMatch = chord.match(/([A-G]#?|b?)/);
    if (!rootMatch) return chord;
    let root = rootMatch[0];
    let suffix = chord.replace(root, "");

    let index = NOTES.indexOf(root);
    if (index !== -1) {
        let newIndex = (index + steps + NOTES.length) % NOTES.length;
        return NOTES[newIndex] + suffix;
    }
    return chord;
}

export default function StickyBar({ transposition, setTransposition, currentKey, scrollSpeed, setScrollSpeed }) {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        let scrollInterval;
        if (isScrolling) {
            scrollInterval = setInterval(() => {
                window.scrollBy(0, 1);
            }, 200 / scrollSpeed);
        } else {
            clearInterval(scrollInterval);
        }
        return () => clearInterval(scrollInterval);
    }, [isScrolling, scrollSpeed]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "r") {
                setIsScrolling(!isScrolling);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [isScrolling]);

    return (
        <div style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            backgroundColor: "#f8f9fa",
            padding: "10px 0",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1000,
            opacity: isScrolling ? 0.5 : 1
        }}>
            {/* Controlli di trasposizione */}
            <div style={{
                display: isScrolling ? "none" : "flex",
                alignItems: "center",
                paddingLeft: "20px"
            }}>
                <button onClick={() => setTransposition(transposition - 1)}>-</button>
                <span style={{ margin: "0 10px", fontWeight: "bold", fontSize: "18px", color: "#007BFF" }}>{transposeChord(currentKey, transposition)}</span>
                <button onClick={() => setTransposition(transposition + 1)}>+</button>
                <span style={{ marginLeft: "10px" }}>Transpose: {transposition}</span>
            </div>

            {/* Controllo scroll */}
            <div style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                display: "flex",
                alignItems: "center",
                zIndex: 1,
            }}>
                <button onClick={() => setIsScrolling(!isScrolling)}>
                    {isScrolling ? "⏹" : "▶"}
                </button>
            </div>

            {/* Controllo velocità scroll */}
            <div style={{
                display: isScrolling ? "none" : "flex",
                alignItems: "center",
                paddingRight: "20px"
            }}>
                <label htmlFor="scroll-speed" style={{ marginRight: "10px" }}></label>
                <input
                    type="range"
                    id="scroll-speed"
                    min="1"
                    max="5"
                    value={scrollSpeed}
                    onChange={(e) => setScrollSpeed(Number(e.target.value))}
                    style={{ width: "100px" }}
                />
                <span style={{ marginLeft: "10px" }}>{scrollSpeed}</span>
            </div>
        </div>
    );
}
