import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TopBar from "./TopBar";
import StickyBar from "./StickyBar";

const allChords = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'];
const enharmonicMap = { 'A#': 'Bb', 'C#': 'Db', 'D#': 'Eb', 'F#': 'Gb', 'G#': 'Ab' };
const inverseEnharmonicMap = Object.fromEntries(Object.entries(enharmonicMap).map(([sharp, flat]) => [flat, sharp]));

const splitChord = (chord) => {
    const match = chord.match(/^([A-G](#|b)?)(.*)$/);
    if (!match) return [chord, ""];
    return [match[1], match[3]];
};

const transposeChord = (chord, shift) => {
    let [root, suffix] = splitChord(chord);
    let originalBemolle = root.includes('b');
    if (originalBemolle) {
        root = inverseEnharmonicMap[root] || root;
    }
    let index = allChords.indexOf(root);
    if (index === -1) return chord;
    let transposedChord = allChords[(index + shift + allChords.length) % allChords.length];
    if (originalBemolle && enharmonicMap[transposedChord]) {
        transposedChord = enharmonicMap[transposedChord];
    }
    return transposedChord + suffix;
};

const detectInitialKey = (lyrics) => {
    const chordRegex = /\b[A-G](#|b)?(m|dim|aug|sus[24]?|maj7?|m7?|7#?\d?|9|11|13)?\b/g;
    const chords = lyrics.match(chordRegex);
    if (!chords || chords.length === 0) return 'C';
    const chordCounts = {};
    chords.slice(0, 10).forEach((chord) => {
        chordCounts[chord] = (chordCounts[chord] || 0) + 1;
    });
    const sortedChords = Object.entries(chordCounts).sort((a, b) => b[1] - a[1]);
    const mostFrequentChord = sortedChords[0] ? sortedChords[0][0] : chords[0];
    return convertToMajorKey(mostFrequentChord);
};

const convertToMajorKey = (key) => {
    const minorToMajor = {
        'Am': 'C', 'Bm': 'D', 'Cm': 'Eb', 'Dm': 'F', 'Em': 'G',
        'Fm': 'Ab', 'Gm': 'Bb'
    };
    return minorToMajor[key] || key;
};

const transposeKey = (originalKey, shift) => {
    const allKeys = ['A', 'A#', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'];
    const index = allKeys.indexOf(originalKey);
    if (index === -1) return originalKey;
    return allKeys[(index + shift + allKeys.length) % allKeys.length];
};

export default function Song() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const songTitle = queryParams.get("title");

    const [lyrics, setLyrics] = useState("");
    const [transposition, setTransposition] = useState(0);
    const [originalKey, setOriginalKey] = useState('C');
    const [currentKey, setCurrentKey] = useState('C');
    const [scrollSpeed, setScrollSpeed] = useState(2);

    useEffect(() => {
        fetch(`/lyrics/${songTitle}.html`)
            .then((response) => response.text())
            .then((data) => {
                setLyrics(data);
                const detectedKey = detectInitialKey(data);
                setOriginalKey(detectedKey);
                setCurrentKey(detectedKey);
                const velocityMatch = data.match(/Velocity:\s*(\d+)/);
                if (velocityMatch) {
                    setScrollSpeed(Number(velocityMatch[1]));
                }
            });
    }, [songTitle]);

    useEffect(() => {
        setCurrentKey(transposeKey(originalKey, transposition));
    }, [transposition, originalKey]);

    const transposeLyrics = (lyricsText, shift) => {
        return lyricsText.replace(/\b[A-G](#|b)?(m|dim|aug|sus[24]?|maj7?|m7?|7#?\d?|9|11|13)?\b/g, (match) =>
            transposeChord(match, shift)
        );
    };

    return (
        <div className="flex flex-col min-h-screen bg-white text-black p-14 sm:p-15">
            <TopBar />
            <div className="flex-grow">
                {lyrics.split("\n").map((line, index) => (
                    <pre key={index} style={{ wordBreak: "break-word", whiteSpace: "pre-wrap" }}>
                        {index % 2 !== 0 || index === 0 ? line.trimEnd() : <b>{transposeLyrics(line.trimEnd(), transposition)}</b>}
                        <br />
                    </pre>
                ))}
            </div>
            <StickyBar transposition={transposition} setTransposition={setTransposition} currentKey={currentKey} scrollSpeed={scrollSpeed} setScrollSpeed={setScrollSpeed} />
        </div>
    );
}
