import React, {useState} from 'react';

export default function Elegant() {
    const [searchTerm, setSearchTerm] = useState('');

    const songs = [
        {name: 'Wicked Game - Chris Isaak'},
        {name: 'Iris - Biagio Antonacci'},
        {name: 'Fix You - Coldplay'},
        {name: 'All I Want - Kodaline'},
        {name: 'We Found Love - Rihanna'},
        {name: 'La Nuova Stella Di Broadway - Cesare Cremonini'},
        {name: 'Wonderwall - Oasis'},
        {name: 'The Scientist - Coldplay'},
        {name: 'La Rondine - Mango'},
        {name: 'L\'emozione Non Ha Voce - Adriano Celentano'},
        {name: 'Menomale Che C\'è Il Mare - Olly'},
        {name: 'Tango - Tananai'},
        {name: 'Balorda Nostalgia - Olly'},
        {name: 'Destri - Gazzelle'},
        {name: 'Estate - Negramaro'},
        {name: 'Luce (Tramonti A Nord Est) - Elisa'},
        {name: 'Due Vite - Marco Mengoni'},
        {name: 'A Mano A Mano - Rino Gaetano'},
        {name: 'Meraviglioso - Negramaro'},
        {name: 'Pastello Bianco - Pinguini Tattici Nucleari'},
        {name: 'Per dimenticare - Zero Assoluto'},
        {name: 'Sere Nere - Tiziano Ferro'},
        {name: 'Solo 3 Min - Negramaro'},
        {name: 'Tappeto di fragole - Modà'},
        {name: 'Tattoo - Loreen'},
        {name: 'Con Il Nastro Rosa - Lucio Battisti'},
        {name: 'Coraline - Maneskin'},
        {name: 'Don\'t You Worry Child - Sweddish House Mafia'},
        {name: 'Crazy - Gnarls Barkley'},
        {name: 'Me So Mbriacato - Alessandro Mannarino'},
    ];

    const songLinks = songs.map(song => ({
        title: song.name,
        link: '/#/song?title=' + song.name.toLowerCase().split(' ').join('-').replace(/-+/g, '-')})
    );

    const filteredSongs = songLinks.filter(song =>
        song.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className={'flex items-center flex-col p-14 sm:p-15 text-black'}>
            <h1 className={"p-3"}><b>ELEGANT</b></h1>
            <input
                className={"p-3 m-5"}
                type="text"
                placeholder="Search songs..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
            />
            <ul>
                {filteredSongs.map(song => (
                    <li className={"p-2"} key={song}>
                        <a href={song.link}>{song.title}</a>
                    </li>
                ))}
            </ul>
        </div>
    );
}