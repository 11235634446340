import backgroundVideo from '../assets/videos/background.mp4';
import { Outlet, useLocation } from 'react-router-dom';

export default function Layout() {
    const location = useLocation();
    const isSongPage = location.pathname.includes('/song'); // Adjust the path if needed

    return (
        <div className={'flex justify-center'}>
            {!isSongPage && (
                <video className='fixed -z-10 min-w-full min-h-full object-cover' autoPlay loop muted playsInline>
                    <source src={backgroundVideo} type='video/mp4' />
                </video>
            )}
            <div className={'bg-zinc-50/75 rounded-3xl my-12 mx-4'}>
                <Outlet />
            </div>
        </div>
    );
}
