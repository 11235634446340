import React from 'react';
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import './index.css';
import Layout from './pages/Layout';
import Homepage from "./pages/Homepage";
import Repertoire from "./pages/Repertoire";
import Street from "./pages/Street";
import Elegant from "./pages/Elegant";
import Sing from "./pages/Sing";
import Song from "./components/Song";

export default function App() {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Homepage />} />
                    <Route path="repertoire" element={<Repertoire />} />
                    <Route path="repertoire/street" element={<Street />} />
                     <Route path="repertoire/elegant" element={<Elegant />} />
                    <Route path="repertoire/sing" element={<Sing />} />
                    <Route path="song" element={<Song />} />
                </Route>
            </Routes>
        </HashRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
